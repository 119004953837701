<template>
  <ListPart
      :fetchData="fetchData"
      :itemKey="itemKey"
  >
    <template
        v-slot:default="{
          items
        }"
    >
      <v-tabs
          v-model="tabs"
          :vertical="true"
      >
        <v-tab
            v-for="(_Item, i) in items"
            :key="i"
        >
          <span
              v-if="_Item.id === 246"
              class="text-decoration-line-through"
          >
            {{ _Item.title }}
          </span>

          <template
              v-else
          >
            {{ _Item.title }}
          </template>
        </v-tab>

        <v-tab-item
            v-for="(_Item, i) in items"
            :key="i"
        >
          <CardPart>
            <template
                v-slot:default
            >
              <div
                  v-html="_Item.content"
              />
            </template>
          </CardPart>
        </v-tab-item>
      </v-tabs>
    </template>
  </ListPart>
</template>

<script>
  import Register from '@/services/register'

  import ListPart from '@/components/_Part/List'
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Positions',
    components: {
      ListPart,
      CardPart
    },
    data() {
      return {
        itemKey: 'indexes',
        tabs: 0
      }
    },
    methods: {
      fetchData() {
        return Register
          .indexesIndexGroupValue({
            indexGroupValue: 'volunteer_position'
          })
      }
    }
  }
</script>
