<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="updateStatusFreeData"
      @onSave="onUpdateStatusFree"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-textarea
            v-model="ItemForm.note"
            :error-messages="errors.note"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Prosím uveďte poznámku"
            :rows="2"
        />
      </v-col>

      <v-col
          v-if="Item.volunteerId"
          cols="12"
      >
        <a
            class="primary--text"
            @click.stop.prevent="$refs.preview.$el.click()"
        >
          <v-icon
              color="primary"
          >
            mdi-magnify
          </v-icon>

          Chcem zobraziť dobrovoľníka
        </a>

        <Preview
            ref="preview"
            :id="Item.volunteerId"
            class="d-none"
        />
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Interviews from '@/services/interviews'

  import SaveModal from '@/components/_Part/_Modal/Save'
  import Preview from '@/components/Volunteers/Preview'

  export default {
    name: 'UpdateStatusFree',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdateStatusFree'
    ],
    components: {
      SaveModal,
      Preview
    },
    data() {
      return {
        color: 'error',
        icon: 'mdi-karate',
        maxWidth: 500,
        buttonYes: 'Uvoľniť',
        itemKey: 'Interview'
      }
    },
    methods: {
      fetchData() {
        return Interviews
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Uvoľniť pohovor'
      },
      setSubtitle(Item) {
        return Item.adminInitials + ' | ' + this.$moment(Item.datePlain).format('DD.MM.YYYY') + ' | ' + Item.startPlain + ' - ' + Item.endPlain
      },
      setItemForm() {
        return {
          note: null
        }
      },
      updateStatusFreeData(ItemForm) {
        return Interviews
          .updateStatusFree({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdateStatusFree(response) {
        this.$emit('onUpdateStatusFree', response)
      }
    }
  }
</script>
