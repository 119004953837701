<template>
  <ModalPart
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <CardPart
              subtitle="Osobné údaje"
          >
            <template
                v-slot:default
            >
              <v-row
                  align="center"
              >
                <v-col
                    cols="12"
                    lg="6"
                >
                  <v-img
                      :src="$global.cdnUrl() + 'media/files/by-token/' + Item.photoToken + '/240/240'"
                      alt="Fotografia"
                      max-width="240px"
                      max-height="240px"
                  />
                </v-col>

                <v-col
                    cols="12"
                    lg="6"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h3>
                        Pohlavie:
                      </h3>

                      <p>
                        {{ Item.genderEnum }}
                      </p>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <h3>
                        Meno:
                      </h3>

                      <p>
                        {{ Item.name }}
                      </p>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <h3>
                        E-mail:
                      </h3>

                      <p>
                        {{ Item.email }}
                      </p>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <h3>
                        Telefón:
                      </h3>

                      <p>
                        {{ Item.phone }}
                      </p>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <h3>
                        Dátum narodenia:
                      </h3>

                      <p>
                        {{ Item.birthDatePlain|moment('DD.MM.YYYY') }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            v-if="Item.interviewsTotal > 1"
            cols="12"
        >
          <v-alert
              type="warning"
          >
            Počet pohovorov: {{ Item.interviewsTotal }}
          </v-alert>
        </v-col>

        <v-col
            v-if="$calculate.eventMinor(Item.birthDatePlain)"
            cols="12"
        >
          <CardPart
              subtitle="Zákonný zástupca"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h3>
                    Meno:
                  </h3>

                  <p>
                    {{ Item.parentName }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    E-mail:
                  </h3>

                  <p>
                    {{ Item.parentEmail }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Telefón:
                  </h3>

                  <p>
                    {{ Item.parentPhone }}
                  </p>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              subtitle="Dotazník"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h3>
                    Vzdelanie
                  </h3>

                  <p>
                    {{ Item.education }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Škola
                  </h3>

                  <p>
                    {{ Item.school }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Zamestnanie
                  </h3>

                  <p>
                    {{ Item.employment }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Jazykové znalosti
                  </h3>

                  <p>
                    {{ Item.languageSkills.map((_languageSkill) => _languageSkill.type + ' (' + _languageSkill.level + ')').join(', ') }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    PC znalosti
                  </h3>

                  <p>
                    {{ Item.pcSkills.map((_pcSkill) => _pcSkill.type + ' (' + _pcSkill.level + ')').join(', ') }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Primárne miesto pôsobenia dobrovoľníckej činnosti
                  </h3>

                  <p>
                    {{ Item.placePrimaryEnum }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>Sekundárne miesto pôsobenia dobrovoľníckej činnosti</h3>

                  <ParagraphIsEmpty
                      :value="Item.placeSecondaryEnum"
                  >
                    {{ Item.placeSecondaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Aké sú Vaše skúsenosti s dobrovoľníckou činnosťou?
                  </h3>

                  <p>
                    {{ Item.volunteerActivity }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Čo je Vašou motiváciou pridať sa medzi dobrovoľníkov?
                  </h3>

                  <p>
                    {{ Item.volunteerMotivation }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Ktoré Vaše povahové vlastnosti Vás najviac vystihujú?
                  </h3>

                  <p>
                    {{ Item.characterTraits }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Názov klubu
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.footballClubMember"
                  >
                    {{ Item.footballClub }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>Športový klubu</h3>

                  <ParagraphIsEmpty
                      :value="Item.sportClubMember"
                  >
                    {{ Item.sportClub }}
                  </ParagraphIsEmpty>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              subtitle="Pozície"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h3>
                    Alternatíva č.1
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.positionPrimaryEnum"
                  >
                    {{ Item.positionPrimaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Alternatíva č.2
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.positionSecondaryEnum"
                  >
                    {{ Item.positionSecondaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>

                <v-col
                    cols="12"
                >
                  <h3>
                    Alternatíva č.3
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.positionTertiaryEnum"
                  >
                    {{ Item.positionTertiaryEnum }}
                  </ParagraphIsEmpty>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <h3>
            Ostatné informácie
          </h3>

          <ParagraphIsEmpty
              :value="Item.other"
          >
            {{ Item.other }}
          </ParagraphIsEmpty>
        </v-col>
      </v-row>
    </template>
  </ModalPart>
</template>

<script>
  import Volunteers from '@/services/volunteers'

  import ModalPart from '@/components/_Part/Modal.vue'
  import CardPart from '@/components/_Part/Card'

  import ParagraphIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Paragraph.vue'

  export default {
    name: 'Preview',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    components: {
      ModalPart,
      CardPart,
      ParagraphIsEmpty
    },
    data() {
      return {
        color: 'primary',
        icon: 'mdi-magnify',
        maxWidth: 750,
        itemKey: 'Volunteer'
      }
    },
    methods: {
      fetchData() {
        return Volunteers
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Dobrovoľník'
      },
      setSubtitle(Item) {
        return Item.name
      }
    }
  }
</script>
