<template>
  <!-- TODO -->
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          :icon="icon"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="auto"
                >
                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="$refs.calendar.prev()"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-spacer/>

                <v-col
                    cols="auto"
                >
                  <v-btn
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="viewMonth"
                  >
                    {{ calendar|moment('MM/YY') }}
                  </v-btn>
                </v-col>

                <v-spacer/>

                <v-col
                    cols="auto"
                >
                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="$refs.calendar.next()"
                  >
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-calendar
                  v-model="calendar"
                  ref="calendar"
                  locale="sk"
                  :type="type"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :events="items"
                  event-start="from"
                  event-end="to"
                  :event-height="52"
                  :event-margin-bottom="2"
                  :interval-height="160"
                  @click:date="viewDay"
                  @change="fetchData"
              >
                <template
                    v-slot:day-header="{
                      date
                    }"
                >
                  <div
                      v-if="$calculate.eventCreate(date)"
                      class="v-calendar-area head"
                  >
                    <CreateDate
                        :date="date"
                        @onCreateDate="refreshData"
                    />

                    <CreateMoreDate
                        :date="date"
                        @onCreateMoreDate="refreshData"
                    />
                  </div>
                </template>

                <template
                    v-slot:day="{
                      date
                    }"
                >
                  <div
                      v-if="$calculate.eventCreate(date)"
                      class="v-calendar-area head"
                  >
                    <CreateDate
                        :date="date"
                        @onCreateDate="refreshData"
                    />

                    <CreateMoreDate
                        :date="date"
                        @onCreateMoreDate="refreshData"
                    />
                  </div>
                </template>

                <template
                    v-slot:event="{
                      event
                    }"
                >
                  <div
                      class="v-calendar-area"
                  >
                    {{ event.adminInitials }} | {{ event.startPlain }} - {{ event.endPlain }}

                    <span class="float-right text-uppercase">{{ event.language }}</span>

                    <template
                        v-if="type === 'day'"
                    >
                      <template
                          v-if="event.volunteerName"
                      >
                        - {{ event.volunteerName }}
                      </template>
                    </template>

                    <v-tooltip
                        v-if="event.note"
                        :bottom="true"
                    >
                      <template
                          v-slot:activator="{
                            on,
                            attrs
                          }"
                      >
                        <span
                            v-on="on"
                            v-bind="attrs"
                        >
                          <v-icon>
                            mdi-information
                          </v-icon>
                        </span>
                      </template>

                      {{ event.note }}
                    </v-tooltip>

                    <br/>

                    <v-btn
                        v-if="event.url"
                        :icon="true"
                        :outlined="true"
                        color="default"
                        :href="event.url"
                        target="_blank"
                    >
                      <v-icon>
                        mdi-video
                      </v-icon>
                    </v-btn>

                    <Update
                        v-if="event.updatable"
                        :id="event.id"
                        @onUpdate="refreshData"
                    />

                    <template
                        v-if="event.status === 'reserved'"
                    >
                      <UpdateStatusClosed
                          :id="event.id"
                          @onUpdateStatusClosed="refreshData"
                      />

                      <UpdateStatusCanceled
                          :id="event.id"
                          @onUpdateStatusCanceled="refreshData"
                      />

                      <UpdateStatusFree
                          :id="event.id"
                          @onUpdateStatusFree="refreshData"
                      />
                    </template>

                    <Delete
                        v-if="event.deletable"
                        :x-small="true"
                        :id="event.id"
                        @onDelete="refreshData"
                    />
                  </div>
                </template>
              </v-calendar>
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import Interviews from '@/services/interviews'

  import CreateDate from '@/components/Interviews/CreateDate'
  import CreateMoreDate from '@/components/Interviews/CreateMoreDate'
  import Update from '@/components/Interviews/Update'
  import UpdateStatusClosed from '@/components/Interviews/UpdateStatusClosed'
  import UpdateStatusCanceled from '@/components/Interviews/UpdateStatusCanceled'
  import UpdateStatusFree from '@/components/Interviews/UpdateStatusFree'
  import Delete from '@/components/Interviews/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Interviews',
    components: {
      CreateDate,
      CreateMoreDate,
      Update,
      UpdateStatusClosed,
      UpdateStatusCanceled,
      UpdateStatusFree,
      Delete,
      BlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-calendar',
        calendar: this.$moment().toISOString(),
        start: null,
        end: null,
        type: 'month',
        interviews: []
      }
    },
    computed: {
      items: function () {
        return this.interviews.map((_Interview) => ({
          ..._Interview,
          name: _Interview.statusEnum,
          from: _Interview.datePlain + ' ' + _Interview.startPlain,
          to: _Interview.datePlain + ' ' + _Interview.endPlain,
          color: _Interview.statusColorEnum
        }))
      }
    },
    methods: {
      setTitle() {
        return 'Pohovory'
      },
      viewMonth() {
        this.type = 'month'
      },
      viewDay({date}) {
        this.calendar = date

        this.type = 'day'
      },
      refreshData() {
        this.fetchData({
          start: this.start,
          end: this.end
        })
      },
      fetchData({start, end}) {
        this.start = start

        this.end = end

        Interviews
          .indexStartAndEnd({
            start: start.date,
            end: end.date
          })
          .then((response) => {
            this.interviews = response.data.data.interviews
          })
          .catch((error) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: error.response.data.message
            })
          })
      }
    }
  }
</script>
